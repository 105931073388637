/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
          (function() {
              var nav;
              nav = $('.nav');

              if(nav.length) {
                  nav.find('[href="' + window.location.pathname + '"]').addClass('active');
              }
          })();
        // JavaScript to be fired on all pages
      },
      finalize: function() {
          (function() {
            var nav;
            nav = $('.banner');
            $(window).scroll(function() {
              var scrollTop = $(window).scrollTop();
              if(scrollTop > 0) {
                nav.addClass('cast-shadow');
              } else {
                nav.removeClass('cast-shadow');
              }
            });
          })();

          (function() {
              var link, tabs;
              link = $('.c-tab-box__nav a');
              tabs = $('.c-tab-box__tabs');

              link.click(function(event) {
                  event.preventDefault();
                  var id = $(this).attr('href');
                  link.removeClass('is-active');
                  tabs.find('.c-tab-box__tab').removeClass('is-active');
                  $(this).addClass('is-active');
                  $(id).addClass('is-active');
              });
          })();

          (function() {
              var scrollTo, el, href;
              scrollTo = $('.scrollTo');
              el = scrollTo.find('a');
              href = el.attr('href');

              if(el.length) {
                  el.click(function(event) {
                      event.preventDefault();
                      $('html, body').animate({scrollTop:$(href).offset().top}, 500, 'swing');
                  });
              }
          })();

          (function() {
              var gallery;
              gallery = $('.p-gallery');
              if(gallery.length) {
                  gallery.magnificPopup({
                      delegate: 'a',
                      type: 'image',
                      mainClass: 'mfp-with-zoom',
                      gallery: {
                          enabled: true,
                          tPrev: 'Poprzednie zdjęcie',
                          tNext: 'Następne zdjęcie',
                          tCounter: '%curr% z %total%'
                      },
                      zoom: {
                          enabled: true,
                          duration: 300,
                          easing: 'ease-in-out',
                          opener: function(openerElement) {
                              return openerElement.is('img') ? openerElement : openerElement.find('img');
                          }
                      },
                      tClose: 'Zamknij (Esc)',
                      tLoading: 'Ładowanie...',
                      image: {
                          tError: '<a href="%url%">Obrazek</a> nie został załadowany.'
                      },
                      ajax: {
                          tError: '<a href="%url%">Zapytanie</a> nie powiodło się.'
                      }
                  });
              }
          })();

          (function() {
              function initializeMap() {
                  var markers = [
                      ['Jantar Szpital', 54.335943,19.0347519],
                      ['Ośrodek Wypoczynkowy Medyk', 54.3361522,19.0279392]
                  ];

                  var infoWindowContent = [
                      ['<div class="info_content"><div class="logo-jantar"></div></div>'],
                      ['<div class="info_content"><div class="logo-medyk"></div></div>']
                  ];

                  var map;
                  var bounds = new google.maps.LatLngBounds();
                  var mapOptions = {
                      mapTypeId: 'roadmap',
                      disableDefaultUI: true,
                      draggable: false,
                      zoomControl: false,
                      scrollwheel: false,
                      disableDoubleClickZoom: true,
                      styles: [{"featureType":"administrative","elementType":"labels.text","stylers":[{"visibility":"off"}]},{"featureType":"administrative.locality","elementType":"labels.text","stylers":[{"visibility":"off"}]},{"featureType":"administrative.neighborhood","elementType":"labels.text","stylers":[{"visibility":"off"}]},{"featureType":"landscape.man_made","elementType":"labels.text","stylers":[{"visibility":"off"}]},{"featureType":"landscape.natural","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#e0efef"}]},{"featureType":"landscape.natural","elementType":"labels.text","stylers":[{"visibility":"off"}]},{"featureType":"poi","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"hue":"#1900ff"},{"color":"#c0e8e8"}]},{"featureType":"poi","elementType":"labels.text","stylers":[{"visibility":"off"}]},{"featureType":"poi","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"geometry","stylers":[{"lightness":100},{"visibility":"simplified"}]},{"featureType":"road","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"labels.text","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit.line","elementType":"geometry","stylers":[{"visibility":"on"},{"lightness":700}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#7dcdcd"}]}]
                  };

                  map = new google.maps.Map(document.getElementById('gmaps'), mapOptions);
                  map.setTilt(45);

                  var infoWindow, marker, i;

                  for( i = 0; i < markers.length; i++ ) {
                      var position = new google.maps.LatLng(markers[i][1], markers[i][2]);

                      bounds.extend(position);

                      marker = new google.maps.Marker({
                          position: position,
                          map: map,
                          title: markers[i][0]
                      });

                      infoWindow = new SnazzyInfoWindow({
                          marker: marker,
                          content: infoWindowContent[i][0],
                          closeOnMapClick: false
                      });

                      infoWindow.open(map, marker);

                      marker.setVisible(false);

                      map.fitBounds(bounds);

                      setTimeout(function() {
                          map.setZoom(16);
                          map.panBy(-130,-30);
                      }, 500);
                  }

                  var boundsListener = google.maps.event.addListener((map), 'bounds_changed', function() {
                      this.setZoom(16);
                      google.maps.event.removeListener(boundsListener);
                  });
              }

              window.gmaps = {
                  init: initializeMap
              };

              $(function() {
                  if( $('#gmaps').length ) {
                      gmaps.init();
                  }
              });
          })();
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    'chirurgia_naczyniowa': {
        init: function() {

        },
        finalize: function() {
            (function() {
                var msnry;
                msnry = $('.gallery-grid');

                if(msnry.length) {
                    msnry.imagesLoaded(function() {
                        var colWidth = msnry.find('.gallery-item').outerWidth();
                        msnry.masonry({
                            itemSelector: '.gallery-item',
                            columnWidth: colWidth,
                            gutter: 20
                        });
                    });
                }
            })();
        }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
